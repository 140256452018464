import React from 'react'
import Seo from '../../../components/Seo'
import BigTitle from '../../../components/BigTitle'
import DefaultSection from '../../../components/DefaultSection'
import Article from '../../../components/Article'
import TeaserSection from '../../../components/TeaserSection'
import TeaserHeader from '../../../components/TeaserHeader'
import NavigationLink from '../../../components/NavigationLink'
import IndividualContactSection from '../../../components/IndividualContactSection'


const Page = () => {
  const jobDescriptionText = `Als IT Consultant Qualitätssicherung Java (m/w/d) liegt Dein Aufgabengebiet in der Konzeption
    und Umsetzung von QA-Maßnahmen auf der Ebene der Software-Architektur, insbesondere unter Nutzung von jQAssistant
    (https://jqassistant.org). Du integrierst das Werkzeug in bestehende CI-Pipelines, konzipierst und verfeinerst mit
    unseren Kunden Architekturregeln und setzt diese für Systeme unterschiedlicher Größenordnungen als ausführbare
    Entwickler-Dokumentation um. Darüber bringen Sie Ihre Erfahrungen aktiv in die konzeptionelle und technische
    Weiterentwicklung von jQAssistant ein.`
  return (
    <React.Fragment>
      <Seo title="IT-Consultant Qualitätssicherung Java (m/w/d)"
           jobTitle="IT-Consultant Qualitätssicherung Java (m/w/d)"
           jobDescription={jobDescriptionText} jobDatePosted="2021-04-14"
           jobIdentifier="itc-q-mwd"/>
      <BigTitle title="Stellenangebot" imageRelativePath={'Titelbild_Karriere.jpg'} className="mask-black"
                textInverted={true}
                menuInverted={true} logoInverted={true}>Du bist auf der Suche nach einem
        spannenden Job in einem innovativen IT-Unternehmen? Dann bist Du hier genau richtig.</BigTitle>

      <DefaultSection>
        <Article>
          <h1>IT-Consultant Qualitätssicherung Java (m/w/d)</h1>
          <br/>
          <h2>Deine Aufgabe</h2>
          <p>Als IT Consultant Qualitätssicherung Java (m/w/d) ist Dein Aufgabengebiet die Konzeption und Umsetzung von
            QA-Maßnahmen auf der Ebene der Software-Architektur unter Nutzung von <NavigationLink
              to={'https://jqassistant.org'} asTextLink ariaLabel="Link zu jQAssistant"> jQAssistant</NavigationLink>.
            Du integrierst das Werkzeug in CI-Pipelines, konzipierst Architekturregeln und setzt diese für Systeme
            unterschiedlichster
            Größenordnungen um. Darüber hinaus bringst Du Deine Erfahrungen in die Weiterentwicklung von jQAssistant
            ein.</p>
          <h2>Dein Profil</h2>
          <p>
            <ul>
              <li>Du hast ein abgeschlossenes Hochschulstudium in der Informatik oder eines verwandten Studienganges.
              </li>
              <li>In der Vergangenheit hast Du bereits Erfahrungen mit Java-Technologien im Unternehmensumfeld (insb.
                Java EE, Spring) gesammelt.
              </li>
              <li>Dein Blick erstreckt sich über Code hinaus auf Architektur- und Qualitätsthemen wie Monolithen vs.
                Microservices, Modularisierung und Software-Metriken.
              </li>
              <li>Idealerweise bringst Du Erfahrungen in der Architekturdokumentation.
              </li>
              <li>Du besitzt ein Verständnis von Auslieferungsprozessen und bist sicher im Umgang mit Versionskontroll-,
                Build- und CI-Systemen.
              </li>
              <li>Neben sehr guten Deutschkenntnissen (mind. C1 Niveau) und guten Englischkenntnissen besitzt Du die
                Fähigkeit, Dich schnell in neue Aufgabenfelder und Technologien einzuarbeiten.
              </li>
              <li>Du bist ein teamfähiger und engagierter Mensch, der strukturiert arbeitet und Verantwortung
                übernimmt.
              </li>
            </ul>
          </p>
          <p> Je nach Berufserfahrung steigst Du bei uns in unsere internen oder in Kunden-Projekte ein und wirst zu
            Beginn von einem erfahrenen Kollegen begleitet.</p>
          <h2>Deine Benefits bei BUSCHMAIS</h2>
          <p>
            <ul>
              <li>Bei uns findest Du ein innovatives Umfeld, in dem Du Dich in der täglichen Praxis, mittels Schulungen
                oder Konferenzbesuchen fachlich weiterentwickelst.
              </li>
              <li>Wir legen viel Wert auf die Vereinbarkeit von Privat- und Berufsleben.</li>
              <li>Uns ist eine direkte Kommunikation enorm wichtig, weswegen die Entscheidungswege so kurz wie nur
                möglich gehalten sind.
              </li>
              <li>Bei uns verdurstet niemand. Kaffee und Tee aus dem Bioladen und frisch gefiltertes Wasser sind
                kostenfrei.
              </li>
              <li>Auch nach Feierabend haben wir ein offenes Ohr - ganz gleich, ob privater oder beruflicher
                Gesprächsstoff.
              </li>
              <li>Langeweile kommt bei uns nicht auf, denn wir veranstalten Ausflüge und Grillabende und nehmen an
                Sportevents teil.
              </li>
            </ul>
          </p>
        </Article>
      </DefaultSection>

      <TeaserSection className="bg-level-1" anchor="bewerben">
        <TeaserHeader title="Begeistert?">
          <p>
            Das freut uns. Sende uns bitte Deine vollständigen Bewerbungsunterlagen, ggf. mit Deinen
            Gehaltsvorstellungen und dem frühestmöglichen Einstiegstermin per E-Mail. Für Rückfragen stehen wir Dir
            gerne zur Verfügung.
          </p>
          <p>Bitte beachte, dass Bewerbungen über die E-Mail-Dienste
            Outlook und Gmail aus technischen Gründen ggf. nicht bei uns ankommen.</p>
          <p className="mt-5">
            <a className="btn btn-outline-black" href="mailto:jobs@buschmais.com"
               title="Jetzt per Mail bewerben">Jetzt per Mail bewerben</a>
          </p>
        </TeaserHeader>
      </TeaserSection>

      <IndividualContactSection personKey={'christiane'} email={'jobs@buschmais.com'} tel={'+49 351 320923 11'}/>

    </React.Fragment>
  )
}

export default Page
